import React, { useState, useEffect } from 'react'
import "./HomeWork.scss"
import { useInView } from 'react-intersection-observer'
import { HomeWorkCard } from '../../organism/HomeWorkCard'
import { GatsbyImage } from 'gatsby-plugin-image'

const HomeWork = ({ data }) => {
    const [workCounter, setWorkCounter] = useState(4);
    const { ref, inView, entry } = useInView({
        threshold: 0.5,
    });

    const divStyle = {
        background: 'url('+data.titleImageDesktop.file.url+') 0 50% no-repeat',
    }

    return (
        <section ref={ref} id="work" className={`animated-block ${inView ? "active" : ""}`}>
            <div id="block-work" className="block-work inner-page">
                <div className="inner-wrap">
                    <div className="content">
                        <div className="title title-mob" style={divStyle}>
                            <GatsbyImage image={data.titleImageMobile.gatsbyImageData}/>
                        </div>
                        <div className="work-wrap">
                            {
                                data.workItems.slice(0, workCounter).map((work, index) =>
                                    <HomeWorkCard data={work} logoDiscover={data.logoDiscover} key={index} />
                                )
                            }
                        </div>
                        {
                            (workCounter >= data.workItems.length) ? null
                                : <div onClick={() => setWorkCounter(prev => prev + 2)} className="btn-more">
                                    <p>EN VOIR +</p>
                                </div>
                        }
                    </div>
                </div>
            </div>
        </section>

    )
}

export default HomeWork