import React from 'react'
import IntroVideo from "../../../assets/videos/Vision_film_short.mp4"
import "./HomeIntro.scss"
import ReactPlayer from 'react-player'
import { isBrowser } from '../../../../Utils/Utils'

const HomeIntro = ({data}) => {

    const arrowHandle = () => {
        let scrollEl = document.querySelector('#think').offsetTop

        if (!isBrowser) {
            return;
        }
        return window.scrollTo({ top: scrollEl, behavior: 'smooth' });
    }

    var videoPath = "https://everydaycontent.fr/videos/";

    console.log();
    return (
        <section id="intro" className="intro">
            <div className="block-intro inner-page">
                <div className="video-wrap">
                    <ReactPlayer url={videoPath + data.linkVideo}
                        playing={true} loop
                        controls={false}
                        muted={true}
                        width="100%"
                        height="100%"
                    />
                </div>
                <div onClick={() => arrowHandle()} className="down-arrow"></div>
            </div>
        </section>

    )
}

export default HomeIntro