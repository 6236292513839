import React, { useState, useEffect, useRef } from 'react';
import { Link, graphql } from 'gatsby';

import Layout from '../layout/Layout';
import SEO from '../components/Seo';
import './HomeStyles.scss'
import HomeThink from '../components/blocks/HomeThink/HomeThink';
import HomeIntro from '../components/blocks/HomeIntro/HomeIntro';
import HomeWork from '../components/blocks/HomeWork/HomeWork';
import HomeCreators from '../components/blocks/HomeCreators/HomeCreators';
import HomeLive from '../components/blocks/HomeLive/HomeLive';



const Index = ({ data }) => {
  const homePageData = data?.allContentfulPageHome?.nodes[0]
  const thinkBlockData = data?.allContentfulHomeBlockThink?.nodes[0];
  const workBlockData = data?.allContentfulHomeBlockWork?.nodes[0];
  const creatorsData = data?.allContentfulHomeBlockCreators?.nodes[0];
  const liveBlockData = data?.allContentfulHomeBlockLive?.nodes[0];
  const homePageVideoData = data?.allContentfulHomeBlockIntroVideo?.nodes[0];

  return (
    <Layout>
      <SEO
        lang="fr"
        title={homePageData?.tileOfThePage || 'Everyday Content - HomePage'}
        description={homePageData?.descriptionOfThePage || 'Everyday Content - HomePage'}
      />
      <div className="page_home page-wrapper homepage">
        <HomeIntro data={homePageVideoData}/>
        <HomeThink data={thinkBlockData} />
        <HomeWork data={workBlockData} />
        <HomeCreators data={creatorsData} />
        <HomeLive data={liveBlockData} />
      </div>
    </Layout>
  );
};

export const query = graphql`
  {
    allContentfulHomeBlockIntroVideo {
      nodes {
        linkVideo
      }
    }
    allContentfulPageHome {
      nodes {
        tileOfThePage
        descriptionOfThePage
      }
    }
    allContentfulHomeBlockThink {
      nodes {
        blockName
        title
        description {
          description
        }
        thinkItems {
          title
          description {
            description
          }
        }
        titleImageDesktop {
          file{
            url
          }
        }
        titleImageMobile {
          gatsbyImageData
        }
      }
    }
    allContentfulHomeBlockWork {
      nodes {
        logoDiscover {
          gatsbyImageData
        }
        blockName
        workItems {
          clientName
          pageUrl
          urlVideo
          logo{
            gatsbyImageData
          }
          clientNameImage {
            gatsbyImageData
          }
          visuelRollout {
            gatsbyImageData
          }
          visuelRolloverGif {
            gatsbyImageData
          }
          
        }
        titleImageDesktop {
          file{
            url
          }
        }
        titleImageMobile {
          gatsbyImageData
        }
      }
    }
    allContentfulHomeBlockCreators {
    nodes {
      title
      description {
        childMarkdownRemark {
          html
        }
      }
      titleImageDesktop {
        gatsbyImageData
      }
    }
  }
    allContentfulHomeBlockLive {
      nodes {
        blockName
        image1Text {
          gatsbyImageData
        }
        image2Photo {
          gatsbyImageData
        }
        image3Text {
          gatsbyImageData
        }
        image4Photo {
          gatsbyImageData
        }
        image5Text {
          gatsbyImageData
        }
        image6Photo {
          gatsbyImageData
        }
        image7Text {
          gatsbyImageData
        }
        image8Photo {
          gatsbyImageData
        }
        titleImageDesktop {
          file{
            url
          }
        }
        titleImageMobile {
          gatsbyImageData
        }
      }
    }
  }
`

export default Index;
