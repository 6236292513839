import React, { useRef } from 'react'
import "./HomeThink.scss"
import BulleVert from "../../../assets/images/bulle_vert_deg.png"
import { HomeThinkCard } from '../../organism/HomeThinkCard'
import { useInView } from 'react-intersection-observer'
import { GatsbyImage } from 'gatsby-plugin-image'

const HomeThink = ({ data }) => {
	const { ref, inView, entry } = useInView({
		threshold: 0.5,
	});

	const divStyle = {
		background: 'url('+data.titleImageDesktop.file.url+') 0 50% no-repeat',
	}

	return (
		<section ref={ref} id="think" className={`animated-block ${inView ? "active" : ""}`}>
			<div className="block-think inner-page">
				<div className="inner-wrap">
					<div className="title" style={divStyle}>
						<GatsbyImage image={data.titleImageMobile.gatsbyImageData} />
					</div>
					<div className="content">
						<div className="txt-intro">
							<div className="inner-content-wrap">
								<h3 className="tt-3">{data.title}</h3>
								<p>{data.description.description}</p>
							</div>
							<div className="bulle_deg">
								<img src={BulleVert} alt="" />
							</div>
						</div>
						<div className="bloc-methodology">
							<div className="box-wrap">
								{
									data.thinkItems.map((item, index) =>
										<HomeThinkCard key={item.id + '-' + index} title={item.title} description={item.description.description} />
									)
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default HomeThink