import React from 'react'

export const HomeThinkCard = ({ title, description }) => {
    return (
        <div className="box">
            <span className="bulle">{title}</span>
            <p>
                {description}
            </p>
        </div>
    )
}
