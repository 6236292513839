import React, { useState } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import imgDiscover from '../../assets/images/discover_logo.png'

var classNames = require('classnames');

export const HomeWorkCard = ({ data, logoDiscover }) => {
    const [active, setActive] = useState(false)

    var url = "/works/"+data.pageUrl

    return (
        <a href={url}
            onMouseEnter={() => setActive(true)}
            onMouseLeave={() => setActive(false)}
            className={`work ${active ? "active" : ""}`}
        >
            <div className="video-wrap">
                <GatsbyImage className={classNames('vid img-static')} image={data.visuelRollout.gatsbyImageData} alt={data.pageUrl} />
                <GatsbyImage className={classNames('vid')} image={data.visuelRolloverGif.gatsbyImageData} alt={data.pageUrl} />
                <div className="logo-wrap logo-brand">
                    <GatsbyImage image={data.clientNameImage.gatsbyImageData} alt={data.pageUrl} />
                </div>
                <div className="logo-wrap logo-discover">
                    {/* <GatsbyImage image={logoDiscover} alt="logo discover" /> */}
                    <img src={imgDiscover} alt="" />
                </div>
            </div>
        </a>
    )
}
